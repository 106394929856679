/**
 * Type: ページ
 * What: チームラボフォレスト 福岡 – SBI証券ページ
 */
import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Btn, Button, Text } from '../components/Btn';
import Image from '../util/Image';
import BreadcrumbComponent from '../components/Breadcrumb';
import SimpleSwiper from '../components/Slider.fade';
import TEAM_LAB_CATEGORIES from '../config/Team-lab';
import ContentsList from '../components/ContentsList';
import useMediaQuery from '../util/useMediaQuery';

import mainImg from '../images/teamlabforest/main-teamlabforest-01.jpg';
import mainImg2 from '../images/teamlabforest/main-teamlabforest-02.jpg';
import mainImg3 from '../images/teamlabforest/main-teamlabforest-03.jpg';
import mainImg4 from '../images/teamlabforest/main-teamlabforest-04.jpg';

const pageTitle = 'チームラボフォレスト 福岡 – SBI証券';
const pageDescription =
  'E・ZO FUKUOKAの5階、アート集団チームラボによる新ミュージアム。スマートフォンを片手に探索し、様々な動物を捕まえ、観察し、自分のコレクション図鑑をつくっていく「捕まえて集める森」と、複雑で立体的な空間で、強い身体性を伴って、身体ごとインタラクティブな世界に没入する「運動の森」からなる新しいミュージアム';
const pageSlug = 'teamlabforest';
const pageLogo = `logo-${pageSlug}-w.png`;
const pagebtnLogo = `btn-${pageSlug}.png`;

export const query = graphql`
  {
    site {
      siteMetadata {
        uuidTeamLab
        ticket
        ticketReady
      }
    }
  }
`;

const mq = useMediaQuery;

const sliderDataobj = [
  {
    filename: mainImg,
    noAnimation: true,
  },
  {
    filename: mainImg2,
    noAnimation: true,
  },
  {
    filename: mainImg3,
    noAnimation: true,
  },
  {
    filename: mainImg4,
    noAnimation: true,
  },
];

// Data Props Template
const Template = ({ data }) => {
  const url = data.site.siteMetadata;

  const [isModal, setIsModal] = useState(-1);

  // モーダル展開
  const onClickModal = (e) => {
    const num = Number(e.currentTarget.dataset.modal);
    setIsModal(num);
  };

  // モーダル閉じる
  const onCloseModal = () => {
    setIsModal(-1);
  };

  // モーダルコンテンツ
  const ModalContent = () => {
    const theme =
      TEAM_LAB_CATEGORIES[isModal] && TEAM_LAB_CATEGORIES[isModal].theme;
    const name =
      TEAM_LAB_CATEGORIES[isModal] && TEAM_LAB_CATEGORIES[isModal].name;
    const detail =
      TEAM_LAB_CATEGORIES[isModal] && TEAM_LAB_CATEGORIES[isModal].detail;
    const img =
      TEAM_LAB_CATEGORIES[isModal] && TEAM_LAB_CATEGORIES[isModal].img;
    const colorClass =
      TEAM_LAB_CATEGORIES[isModal] && TEAM_LAB_CATEGORIES[isModal].colorClass;
    const external =
      TEAM_LAB_CATEGORIES[isModal] && TEAM_LAB_CATEGORIES[isModal].external;
    return (
      <div className={`modal e-modal ${isModal > -1 ? 'is-active' : ''}`}>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="modal-background" onClick={onCloseModal} />
        <button
          type="button"
          className="delete"
          aria-label="close"
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease-out-expo"
          onClick={onCloseModal}
        />
        <div className={`modal-content c-${colorClass}`}>
          <div className="e-modal-image">
            <figure className="image">
              <Image filename={img || 'now-printing.jpg'} />
            </figure>
          </div>
          <div className="e-modal-content">
            <p className="theme">{theme}</p>
            <h3 className="ttl c-4">{name}</h3>
            <p dangerouslySetInnerHTML={{ __html: detail }} />
            <div className="btn-wrap">
              <Button href={external} className="btn-regular" target="_blank">
                詳細はこちら
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="pageMain bg-0">
        <div className="kv-inner">
          <div className="kv-wrap">
            <div className="kv-inner">
              <SimpleSwiper data={sliderDataobj} />
              <p className="credit">
                &copy;「チームラボフォレスト 福岡 - SBI証券」 福岡 E・ZO FUKUOKA
              </p>
            </div>
            <div className="container">
              <div className="inner">
                <div className="pageMain-header">
                  <div className="floorNo c-9">
                    <span>5</span>
                    <span>F</span>
                  </div>
                  <h2 className="ttl">
                    <Image src={pageLogo} alt={pageTitle} />
                  </h2>
                </div>
                {url.ticketReady === '1' ? (
                  <div
                    className="btn-wrap mb-0"
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                  >
                    <Button
                      className="btn-buy"
                      /* [↓ リンク修正がある場合] url.uuidTeamLab: .env.**のUUID_TEAMLABを変更する */
                      href={`${url.ticket}#/order?lang=ja&id=${url.uuidTeamLab}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      チケットを探す
                    </Button>
                  </div>
                ) : null}
                {/* <div className="btn-group mt-5">
                  <Link
                    to="/news/info/20230311252/"
                    className="btn-def caution-ticket-btn narrow"
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                  >
                    <span>
                      新型コロナウイルス感染防止の対策について
                    </span>
                  </Link>
                </div> */}
                <div className="caution-border mt-5 mb-5">
                  かかとの高い靴、サンダルなどのお足元が不安定な靴ではご体験いただけない作品が多くあります。
                </div>
                <div className="youtube-wrap mt-4 mb-4 cn-hide">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/FCmDC_xicJQ"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="inner">
            <div
              className="btn-wrap mb-5"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              <Button
                href="https://www.teamlab.art/jp/e/forest/"
                target="_blank"
                rel="noopener noreferrer"
              >
                公式サイトはこちらから
              </Button>
            </div>
          </div>
          <h2 className="headline" id="area">
            <span>作品</span>
          </h2>
          <div className="sc-columns now-event sp-mb40">
            {TEAM_LAB_CATEGORIES.map((categories, index) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <div
                className={`col col-4 ${categories.colorClass}`}
                key={categories.name}
                data-modal={index}
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                onClick={(e) => onClickModal(e)}
              >
                <ContentsList {...categories} />
              </div>
            ))}
          </div>
          <h2 className="headline" id="area">
            <span>プロダクト</span>
          </h2>
          <div className="sc-columns now-event sp-mb40 col-center">
            <div
              className="col floorbc-tr3"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-tr-product.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h3 className="ttl">お絵かきファクトリー</h3>
                  <p className="text">
                    あなたが描いた絵がプロダクトになって、持ち帰れます。
                  </p>
                  <p className="text">
                    《グラフィティネイチャー》で、あなたが描いて生まれた生き物は、ここでオリジナルの缶バッジ、タオル、Tシャツ、トートバッグになります。
                  </p>
                  <p className="text mt10">缶バッチ　￥500</p>
                  <p className="text">ハンドタオル　￥1,500</p>
                  <p className="text">Tシャツ（子供）￥2,900</p>
                  <p className="text">Tシャツ（大人）￥3,800</p>
                  <p className="text">トートバック　￥3,800</p>
                  <p className="text mt10">※別途料金が必要となります。</p>
                  <p className="text">
                    ※お絵かきファクトリーのみの体験、購入はできかねます。
                  </p>
                  <p className="text">
                    ※画像はイメージとなります。実際の作品と異なる場合がございますので、予めご了承ください。
                  </p>
                  <p className="text">
                    ※プロダクトの最終注文受付は18:30となります。
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="caution-border mb-6">
            <p className="mb-2">
              ウェディングフォト（前撮り/フォトウェディング）で
              <br className="sp" />
              ご利用いただくことができます。
            </p>
            <a
              href="https://www.softbankhawks.co.jp/stadium/wedding_photo/"
              target="_blank"
              rel="noopener noreferrer"
              className="tx-link c-12"
            >
              詳しくはこちら
            </a>
          </div>
          <div className="inner-slim">
            <div className="table-base">
              <table>
                <tbody>
                  <tr>
                    <th>料金</th>
                    <td>
                      <p className="fwb">
                        大人
                        <br />
                        16歳以上
                      </p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>2,700円</b>
                        </dd>
                      </dl>
                      <dl className="base-dl">
                        <dt>レギュラー</dt>
                        <dd>
                          <b>2,400円</b>
                        </dd>
                      </dl>

                      <p className="fwb mt30">高校生・大学生・専門学生</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>2,200円</b>
                        </dd>
                      </dl>
                      <dl className="base-dl">
                        <dt>レギュラー</dt>
                        <dd>
                          <b>2,000円</b>
                        </dd>
                      </dl>

                      <p className="fwb mt30">
                        小人
                        <br />
                        4歳～15歳
                      </p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>1,200円</b>
                        </dd>
                      </dl>
                      <dl className="base-dl">
                        <dt>レギュラー</dt>
                        <dd>
                          <b>1,000円</b>
                        </dd>
                      </dl>

                      <p className="mt30">
                        <span>※</span>
                        学生料金の場合、アトラクション受付にて学生証の確認を致します
                      </p>
                      <p className="">
                        <span>※</span>
                        3歳以下は、要保護者同伴（無料）
                      </p>
                      <p>
                        各日程のレギュラー・ピークは空席情報カレンダーでご確認ください。
                      </p>
                      <p>
                        <a href="/ticket/" className="tx-link">
                          空席情報カレンダーはこちら
                        </a>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="mt-2">
                お絵描きファクトリーは免税手続きの対象です。
                <span>
                  詳しくは
                  <a href="/tax-free/" className="tx-link">
                    こちら
                  </a>
                </span>
              </p>
            </div>

            <article>
              <h4 className="ttl-strip">
                下記に該当する方は安全上体験いただけないエリアが多数ございます。
              </h4>
              <ul className="list-base">
                <li>妊娠中の方</li>
                <li>車椅子や杖をご利用の方</li>
                <li>ご自身での自立歩行が難しい方</li>
                <li>お子様を抱っこしている方</li>
              </ul>
              <h4 className="title is-5">注意事項</h4>
              <ul className="list-base">
                <li>
                  館内状況により、ご入場をお待ちいただく場合がございます。
                </li>
                <li>
                  当施設内での怪我、損失、損害、トラブルなどにつきまして、弊社の責めに帰すべき事由がある場合を除き、弊社は一切の責任を負いません。
                </li>
                <li>
                  チケットの転売は禁止しており、転売にて購入いただいたチケットではご入場頂けず、その場合も弊社は一切の責任を負いません。
                </li>
                <li>
                  メンテナンスの関係で、一部の作品をご体験いただけない場合がございます。
                </li>
                <li>
                  会場内は暗く、高低差・不安定な足場の箇所が多数ございます。
                </li>
                <li>
                  かかとが尖っている靴、かかとが高い靴、サンダル、下駄など足元が不安定な靴を履いている方は安全上体験いただけないエリアが多数ございます。
                </li>
                <li>
                  お子様連れのお客様は、保護者様の監督責任のもと安全にお過ごしください。
                </li>
                <li>
                  <b>
                    お子様(12歳以下)のご入場には、保護者の同伴が必要です。保護者1名に対しお子様3名までご入場いただけます。（同伴者もチケット購入が必要です）
                  </b>
                </li>
                <li>
                  展示スペースでの喫煙、アメ・ガムを含む飲食はお断りしております。
                </li>
                <li>
                  <b>会場内にトイレ・休憩室はございません</b>
                </li>
                <li>体調不良・泥酔状態でのご入場はお断りしております。</li>
                <li>
                  作品の特性上、光の点滅/点灯、大きい音などの演出がございます。
                </li>
                <li>
                  全長50cm以上の荷物は1Fもしくは3Fの有料コインロッカーをご利用ください。
                </li>
                <li>
                  ベビーカーではご入場いただけません。3Fベビーカー置き場をご利用ください。
                </li>
                <li>
                  補助犬を除き、動物を連れてのご入場はお断りしております。
                </li>
                <li>フラッシュの利用はご遠慮ください。</li>
                <li>
                  撮影には一脚、三脚、自分撮りスティック等の補助機材は使用できません。
                </li>
                <li>
                  事前承諾のない商業目的の撮影や取材はお断りしております。
                </li>
              </ul>
              <h4 className="ttl-strip">持ち込み禁止物</h4>
              <p>
                危険物、臭気物、生花など
                <br />
                ペットボトル、水筒以外の蓋が無いドリンク類
                <br />
                その他、スタッフが危険であると判断した物品
              </p>
            </article>
          </div>
        </div>
      </section>
      <ModalContent />
    </Layout>
  );
};

export default Template;
