/**
 * Type: コンポーネント
 * What: コンテンツリスト
 */
import { Link } from 'gatsby';
import React from 'react';
import Image from '../util/Image';

export const List = (props) => (
  <div className="col-inner">
    <div className="col-image">
      {props.label ? (
        <span className="tag is-food">{props.label}</span>
      ) : null}
      <figure className="image">
        <Image filename={props.img ? props.img : 'now-printing.jpg'} />
        {props.hvImg ? (
          <Image src={props.hvImg} alt="" className="hv-image" />
        ) : null}
      </figure>
    </div>
    <div className={`col-content ${props.bkClass ? props.bkClass : ''}`}>
      {props.categories === 'team-lab' ? (
        <p className="ttl-cate">
          {props.theme}
        </p>
      ) : null}
      <h3 className="ttl">
        {props.name}
      </h3>
      {props.categories !== 'team-lab' ? (
        <p className="text">
          {props.description}
        </p>
      ) : null}
    </div>
  </div>
);

const ContentsList = (props) => (
  <>
    {props.link ? (
      <Link to={props.link}>
        <List {...props} />
      </Link>
    ) : (
      <>
        {props.hvImg ? (
          <div className="hv-link">
            <List {...props} />
          </div>
        ) : (
          <div className="no-link">
            <List {...props} />
          </div>
        )}
      </>
    )}
  </>
);

export default ContentsList;
